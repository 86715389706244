import {randomString, clone} from "../../common/js/tool";
import {constructForm, getFormItemList} from "../../common/js/apply";
import {getStorageInfo, getUserWxId} from "../../common/js/storage";
import attendanceItems from "../../common/js/expand/attendance";
import mealItems from "../../common/js/expand/meal";
import volunteerItems from "../../common/js/expand/volunteer";


const AM_PM_END_TIME = ["12:00:00", "18:00:00"]; // 上下午截止时间点

export const judgeNumber = function(type, number, value) {
    let flag = false;
    switch (type) {
        // 大于
        case 1:
            flag = value > number;
            break;
        case 2:
            flag = value >= number;
            break;
        case 3:
            flag = value == number;
            break;
        case 4:
            flag = value < number;
            break;
        case 5:
            flag = value <= number;
            break;
        case 6:
            number = number.split(",");
            if(number.length == 2) {
                flag = value >= number[0] && value <= number[1];
            }
            break;
    }
    return flag;
};

/**
 * 获取条件审批表单key数组
 * @param {*} nodes 审批流数组
 * @param {*} initial 表单初始化值对象
 * @param {*} keys key数组
 * @param {*} detail 表单值缓存对象
 * @param {*} volunteerGroupKeyMap 志工团/组表单控件key 绑定的志工团身份审批节点node_id数组 数据结构{ [key: 表单key]: Array<node_id> }
 */
export const getConditionKeys = function (nodes, initial, keys, detail, volunteerGroupKeyMap) {
    keys = keys || [];
    initial = initial || {};
    nodes.forEach(n => {
        if(n.type == 'branch') {
            n.children.forEach(b => {
                getConditionKeys(b.nodes, initial, keys, detail);
            });
        } else if(n.type == 'condition') {
            if(n.conditions && n.conditions.length > 0) {
                n.conditions.forEach(con => {
                    if(keys.indexOf(con.value.key) === -1) {
                        detail[con.value.key] = initial[con.value.key];
                        keys.push(con.value.key);
                    }
                })
            }
        } else if(volunteerGroupKeyMap && n.leader_option == 6 && n.group_key) {
            if(volunteerGroupKeyMap[n.group_key]) {
                volunteerGroupKeyMap[n.group_key].push(n.node_id);
            } else {
                volunteerGroupKeyMap[n.group_key] = [n.node_id];
            }
        }
    });
    return keys;
}

/**
 * 获取成员抄送节点对象
 * @param {*} flow
 * @param {*} keys
 * @param {*} detail
 */
export const getBookCCFlow = function(flow, keys, detail) {
    if(keys && keys.length > 0) {
        const node = {
            custom_cc: 0,
            ids: [],
            auto_book_cc: true,
            leader_option: 0,
            node_id: `auto_book_cc_${randomString(6)}`,
            self_cc: 0,
            title: "成员抄送",
            type: 2
        };
        const len = flow.length;
        if(len > 0 && flow[len - 1].auto_book_cc) {
            flow.splice(len - 1, 1);
        }
        const ids = node.ids;
        keys.forEach(key => {
            const value = detail[key];
            if(Array.isArray(value)) {
                value.forEach(id => ids.indexOf(id === -1) && ids.push(id));
            }
        });
        if(ids.length > 0) {
            flow.push(node);
        }
    }
    return flow;
}


/**
 * 获取当前组对应志工团身份审批节点的审批人列表
 * @param {*} node 节点信息
 * @param {*} group 志工团信息
 */
export const getNodeVolunteerGroupUsers = function(node, group) {
    let res = [];
    if(group && node.volunteer_type && node.volunteer_type.length > 0) {
        node.volunteer_type.forEach(type => {
            const key = ['group_leader', 'duty_team_leader', 'chargehand', '', 'statisticians'][type - 1];
            if(key && group[key]) {
                res.push(...group[key].split(","));
            }
        });
    }
    return res;
}


export const getTempOtherConfig = function(temp) {
    let otherConfig = {};
    if(temp.other_config) {
        otherConfig = JSON.parse(temp.other_config);
    }
    // 默认申请人不可修改审批人
    if(otherConfig.approveUnchangeable === undefined) {
        otherConfig.approveUnchangeable = true;
    }
    // 默认申请人不可删除抄送人
    if(otherConfig.ccUnDelete === undefined) {
        otherConfig.ccUnDelete = true;
    }
    return otherConfig;
}


export const checkNodes = function(flowList, extraTemp) {
    let flag = true, errorNode = null, ni = null, ci = null;
    for(let i = 0, l = flowList.length; i < l; i++) {
        const node = flowList[i];
        if(node.type == 'level') {
            if(extraTemp && extraTemp.length > 0) {
                let j = 0;
                while(j < extraTemp.length) {
                    const child = extraTemp[j];
                    const { flag: state, errorNode: en, ni: idx } = checkNodes(child.flowList);
                    if (state === false) {
                        flag = false;
                        errorNode = en;
                        ni = j;
                        ci = idx;
                        break;
                    }
                    j++;
                }
            }
        } else {
            // 节点为空 且不是找不到审批人的情况提示
            if((!node.ids || node.ids.length <= 0) && !node.userEmpty) {
                flag = false;
                ni = i;
                errorNode = node;
                break;
            }
        }
    }
    return { flag, errorNode, ni, ci };
}

export const getApplyParams = function(temp, flow, form, data, detailForm) {
    detailForm = detailForm || [];
    const detail = getFormItemList(form);
    let briefList = constructForm(detailForm, data, form, detail);
    const res = {
        user_id: getUserWxId(),
        temp_id: temp.id,
        temp_name: temp.name,
        temp_icon: temp.icon,
        detail: JSON.stringify(data),
        temp_snap: JSON.stringify(detail),
        temp_flow_snap: temp.flow,
        real_flow: JSON.stringify(flow),
        export_detail: JSON.stringify(detailForm)
    }
    if(briefList && briefList.length > 0) {
        res.message_config = JSON.stringify(briefList);
    }
    return res;
}


/**
 * 特殊模板表单值处理
 * @param {*} data 表单值对象
 * @param {*} temp 模板对象
 * @param {*} success 成功回调
 * @param {*} fail 异常回调
 */
export const dealSpecialTempFormData = function(data, temp, success, fail) {
    if (data.leave_type) {
        data.leave_type = {
            id: data.leave_type.id,
            name: data.leave_type.name,
            holiday_name: data.leave_type.holiday_name,
            type: data.leave_type.type,
            h_type: data.leave_type.h_type,
            is_leave_count: data.leave_type.is_leave_count,
        }
        // 请假选择上下午时间 转成具体时间点数据格式方便后端查询
        if(data.start_time && Array.isArray(data.start_time)) {
            data.start_time_h = `${data.start_time[0]} ${AM_PM_END_TIME[data.start_time[1]]}`;
        }
        if(data.end_time && Array.isArray(data.end_time)) {
            data.end_time_h = `${data.end_time[0]} ${AM_PM_END_TIME[data.end_time[1]]}`;
        }
    }
    // 补卡额外传参：上下班标志位
    if (temp.type == 28) {
        data.flag = this.data.initial.flag;
        success();
    }
    // 志工休眠激活70周岁及以上且不在念佛团弹窗提示自动转组念佛团
    else if(temp.type == 40 && data.volunteer && data.volunteer.birthday) {
        const birthday = new Date(data.volunteer.birthday * 1000);
        const today = new Date();
        birthday.setFullYear(birthday.getFullYear() + 70);
        // 超过70周岁
        if(birthday.getTime() < today.getTime()) {
            const group = data.volunteer.volunteerGroup;
            // 不在念佛团
            if(!group || group.is_oa != 1) {
                this.$confirm({
                    title: "自动转组念佛团",
                    content: "70周岁及以上的处于休眠状态的志工，如果要进行休眠激活的话，请先申请转组念佛团，再申请休眠激活。",
                });
                fail();
            } else {
                success();
            }
        } else {
            success();
        }
    } else {
        success();
    }
}

// 根据用户输入获取条件审批流程
export const getFlow = function(nodes, user, detail) {
    let flow = [];
    nodes.forEach(n => {
        // 忽略申请人节点 申请人节点type 为0
        if(n.type != 0) {
            // 分支节点
            if(n.type == 'branch') {
                let branchList = n.children;
                for(let i = 0, l = branchList.length; i < l; i ++) {
                    let item = branchList[i];
                    let branchNodes = item.nodes.filter(bn => bn.type != 'condition');
                    // 默认流程
                    if(item.default) {
                        flow.push(...getFlow(branchNodes, user, detail));
                    } else {
                        // 条件节点
                        let flag = true;
                        let cn = item.nodes.find(bn => bn.type == 'condition');
                        if(cn && cn.conditions && cn.conditions.length > 0) {
                            for(let j = 0, len = cn.conditions.length; j < len; j ++) {
                                let con = cn.conditions[j];
                                // 如果有一个条件不满足 则标记不满足分支条件 跳出循环
                                if(!matchCondition(con, user, detail)) {
                                    flag = false;
                                    break;
                                }
                            }
                        }
                        // 满足此分支条件将此分支下的节点加入流程并跳出循环
                        if(flag) {
                            flow.push(...getFlow(branchNodes, user, detail));
                            break;
                        }
                    }
                }
            } else {
                if(n.leader_option == 1) {
                    n.ids = user.directLeader ? [...user.directLeader] : [];
                } else if(n.leader_option == 2) {
                    let deptLeader = user.deptLeader ? [...user.deptLeader] : [];
                    // 自身为部门负责人 则由直接上级来审批
                    if(deptLeader.indexOf(user.wx_user_id) >= 0) {
                        deptLeader = user.directLeader ? [...user.directLeader] : [];
                    }
                    n.ids = deptLeader;
                } else if(n.leader_option == 5) {
                    n.ids = [user.wx_user_id];
                }
                if(n.self_cc == 1) {
                    if(Array.isArray(n.ids)) {
                        if(n.ids.indexOf(user.wx_user_id) === -1) {
                            n.ids.push(user.wx_user_id);
                        }
                    } else {
                        n.ids = [user.wx_user_id];
                    }
                }
                // 审批节点未找到审批人设置 显示审批人为空 标志位
                if(n.type == 1 && [4,6,7].indexOf(n.leader_option) === -1 && (!n.ids || n.ids.length <= 0)) {
                    n.userEmpty = true
                }
                flow.push(n);
            }
        }
    });
    return flow;
};

export const matchCondition = function(con, user, detail) {
    let flag = false;
    let {wx_user_id, dept, tag} = user;
    let type = con.type;
    let value = con.value;
    // 申请人
    if(type == 1) {
        let applicant = value.applicant;
        if(applicant && applicant.length > 0) {
            flag = applicant.findIndex(b => {
                return (b.type == 'user' && b.id == wx_user_id) ||
                    (dept && b.type == 'department' && dept.indexOf(b.id + '') >= 0) ||
                    (b.type == 'tag' && tag.findIndex(t => t.id == b.id) >= 0);
            }) >= 0;
        }
    } else if(type == 2) {
        // 请假类型
        let t = detail.leave_type;
        if(t && value.type.indexOf(t.id) >= 0) {
            flag = true;
        }
    } else if(type == 5) {
        // 单选
        let val = detail[value.key];
        if(val && value.select.indexOf(val) >= 0) {
            flag = true;
        }
    } else if(type == 6) {
        // 多选
        let val = detail[value.key];
        if(val) {
            val = val.split(",");
            // 完全包括
            if(value.type == 1) {
                flag = true;
                for(let i = 0, l = value.select.length; i < l; i++) {
                    if(val.indexOf(value.select[i]) === -1) {
                        flag = false;
                        break;
                    }
                }
            } else {
                // 其一包括
                for(let i = 0, l = value.select.length; i < l; i++) {
                    if(val.indexOf(value.select[i]) >= 0) {
                        flag = true;
                        break;
                    }
                }
            }
        }
    } else {
        // 数值判断
        flag = judgeNumber(value.type, value.number, detail[value.key]);
    }
    return flag;
}

/**
 * 处理每个控件配置
 * @param {*} item 数据库存储的配置信息
 * @returns {} 表单控件数组或单个表单控件（某些特殊控件会生成多个表单控件）
 */
export const dealControlItem = function(item) {
    let res = item;
    //假勤组件设置
    if (item.component == 'attendance') {
        let items = attendanceItems[item.type];
        if (items) {
            res = clone(items);
            // 设置请假假期类型
            const con = res.find(c => c.key == "leave_type");
            if(con) {
                con.holiday_types = item.holiday_types;
            }
            const start = res.find(c => c.key == "start_time");
            const end = res.find(c => c.key == "end_time");
            // 有请假最小时长设置并且有开始和结束时间控件
            if(item.min_count && start && end) {
                const user = getStorageInfo();
                const type = user.e_user?.type; // 身份类型
                // 若指定了当前身份类型的最小请假时长为1天，则分别指定开始结束时间选择控件的上下午值：指定开始时间控件为上午 结束时间控件为下午
                if(type && item.min_count[type] && item.min_count[type] == 1) {
                    start.noon = "0";
                    end.noon = "1";
                }
            }
        }
    } else if (item.component == 'meal') {
        let items = clone(mealItems);
        const con = items.find(c => c.component == "dining");
        if(con) {
            con.prev = item.prev;
            con.max = item.max;
            con.defaultValue = item.defaultValue;
            con.noGap = item.noGap;
            if (item.placeholder) {
                con.placeholder = item.placeholder;
            }
        }
        res = items;
    } else if (item.component == "volunteer_modify") {
        const type = item.type || "status";
        const items = clone(volunteerItems[type] || []);
        let con;
        // 组件为变更志工状态 设置选择志工表单控件 指定注册状态prop
        if(type == "status") {
            con = items.find(c => c.component == "volunteer");
            if(con) {
                con.register_status = item.register_status;
                con.limit = item.limit;
                con.status = item.status;
            }
        }
        con = items.find(c => c.key == "status");
        if(con) {
            // 申请状态是休眠前状态 修改提示文案
            if(item.status == "previous") {
                con.placeholder = "选择志工后自动填充";
            }
            // 否则设置申请状态表单控件默认值
            else {
                con.defaultValue = item.status;
            }
        }
        res = items;
    } else {
        if (item.component == 'car') {
            item.key = 'car_list'; // 防止之前的版本没有改key值
        }
    }
    return res;
}
