<template>
  <div class="content md" v-if="apply">
    <a-result status="success" title="提交成功">
      <template #extra>
        <a-button key="buy" @click="cancel">返回</a-button>
      </template>
    </a-result>
  </div>
  <div class="content lg no-padding flex-box vertical" v-else-if="info">
    <div class="app-title">{{info.name}}</div>
    <div class="flex-box flex-grow scroll-area">
      <div class="app-form scroll-area">
        <form-area
          ref="applyForm"
          class="apply-form"
          layout="horizontal"
          form-item-row-type="flex"
          hide-btn
          direct
          confirm-text="提交"
          :label-col="{flex: '100px'}"
          :wrapper-col="{flex: 'auto'}"
          :items="items"
          :entity="detail"
          @formChange="handleChange"></form-area>
      </div>
      <div class="app-progress scroll-area">
        <div class="app-progress-title">审批流程</div>
        <div class="app-progress-box">
          <process-list
            v-model="flow"
            :approve-unchangeable="info.otherConfig.approveUnchangeable"
            :cc-un-delete="info.otherConfig.ccUnDelete"
            :extra-apply="info.extraTemp"
            type="apply"></process-list>
        </div>
      </div>
    </div>
    <a-space class="footer" style="margin-top: 0">
      <a-button @click="cancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="submit">提交</a-button>
    </a-space>
  </div>
</template>

<script>

    import {
        checkNodes, dealControlItem, dealSpecialTempFormData, getApplyParams,
        getBookCCFlow,
        getConditionKeys,
        getFlow,
        getNodeVolunteerGroupUsers,
        getTempOtherConfig
    } from "./temp";
    import {getStorageInfo, getTemple} from "../../common/js/storage";
    import {base} from "../../components/icon-select";
    import {constructForm} from "../../common/js/apply";
    import {getDate} from "../../common/js/tool";
    import {convertFormToAnt} from "../../common/hr/form";
    import ProcessList from "../../layouts/ProcessList";

    export default {
        name: "AppApply",
        components: {
            ProcessList
        },
        data() {
            return {
                loading: false,
                info: null,
                detail: {},
                list: [],
                flow: [],
                apply: null,
            }
        },
        computed: {
            id() {
                return this.$route.params.id;
            },
            items() {
                return convertFormToAnt(this.list);
            }
        },
        created() {
            this.getTemp()
        },
        methods: {
            cancel() {
                this.$router.go(-1);
            },
            submit() {
                this.$refs.applyForm.handleConfirm().then(data => {
                    if (this.loading) return;
                    // 检查每个节点
                    const info = this.info;
                    const flow = this.flow;
                    const extraTemp = info.extraTemp
                    const { flag, errorNode, ni, ci } = checkNodes(flow, extraTemp);
                    if (!flag) {
                        // 同级审批中节点出错
                        if (ci !== null) {
                            const child = extraTemp[ni];
                            this.$message.warning(`同级审批${child.name}申请第${ci + 1}个节点的${errorNode.type == 1 ? '审批人' : '抄送人'}不能为空`);
                        } else {
                            this.$message.warning(`第${ni + 1}个节点的${errorNode.type == 1 ? '审批人' : '抄送人'}不能为空`);
                        }
                        return;
                    }
                    this.loading = true;
                    dealSpecialTempFormData(data, info, () => {
                        let form = this.list;
                        let detailForm = [];
                        let res = {
                            // 接口body实体
                            data: getApplyParams(info, flow, form, data, detailForm),
                            detailForm,
                            subedData: data,
                            parsedFlow: flow, // 解析好的审批流 用于同一个页面下的编辑
                            parsedForm: form // 解析好的表单配置 用于同一个页面下的编辑
                        }
                        // 找到接待控件
                        const control = form.find(c => c.component == 'reception');
                        // 接待控件和需求单同时存在
                        if (control && extraTemp && extraTemp.length > 0) {
                            let apply = [];
                            const receptionData = data[control.key];
                            extraTemp.forEach(item => {
                                const val = receptionData.find(r => r.id == item.id);
                                if (val) {
                                    const childData = val.data;
                                    // 没有显示的表单
                                    const leftForm = item.fullForm.filter(c => !c.reception);
                                    // 将主应用表单中相同的字段且子表单中没有的同步到子应用表单
                                    leftForm.forEach(c => {
                                        const key = c.key;
                                        if (form.findIndex(item => item.key == key) >= 0) {
                                            childData[key] = data[key];
                                        }
                                    });
                                    apply.push({
                                        ...getApplyParams(item, item.flowList, item.form, childData),
                                        temple_id: getTemple()
                                    });
                                }
                            });
                            res.extraTemp = extraTemp;
                            res.data.extra_apply = JSON.stringify(apply);
                        }
                        this.insertApply(res.data);
                    }, () => {
                        this.loading = false;
                    });
                });
            },
            insertApply(data) {
                this.$axios.post('/admin/apply', data).then(res => {
                    this.apply = res;
                }).finally(() => this.loading = false);
            },
            handleChange(e) {
                let { conditionKeys: keys, process, ccKeys, volunteerGroupKeyMap } = this.info;
                let { key, value, control } = e;
                if (keys && keys.indexOf(key) >= 0) {
                    const flow = this.getConditionFlow(process);
                    getBookCCFlow(flow, ccKeys, this.data._detail);
                    this.flow = flow;
                }
                if (ccKeys.indexOf(key) >= 0) {
                    this.flow = getBookCCFlow(this.flow, ccKeys, this.detail);
                }
                if (volunteerGroupKeyMap[key] && value) {
                    const flow = this.flow;
                    volunteerGroupKeyMap[key].forEach(id => {
                        const index = flow.findIndex(n => n.node_id == id);
                        if (index >= 0) {
                            let group = value;
                            if (control.valueKey && control.options) {
                                group = control.options.find(o => o[control.valueKey] == group);
                            }
                            const ids = getNodeVolunteerGroupUsers(flow[index], group);
                            this.$set(flow[index], "ids", ids);
                            if (ids.length == 0) {
                                this.$set(flow[index], "userEmpty", true);
                            }
                        }
                    });
                }
                // 返显堂口主管节点成员
                if(control.component == "cl-tk") {
                    const index = this.flow.findIndex(n => n.leader_option == 7);
                    if(index >= 0) {
                        const ids = value && value.admin_user_ids ? value.admin_user_ids.split(",") : [];
                        this.$set(this.flow[index], "ids", ids);
                        if (ids.length == 0) {
                            this.$set(this.flow[index], "userEmpty", true);
                        }
                    }
                }
            },
            dealTemp(info) {
                let initial = {};
                let extraApply = null;
                try {
                    const form = JSON.parse(info.detail);
                    // 审批通过后需要抄送的成员组件key
                    info.ccKeys = form.filter(c => !!c.cc).map(c => c.key);
                    if (info.flow) {
                        let flow = JSON.parse(info.flow);
                        info.process = flow;
                        info.volunteerGroupKeyMap = {}
                        info.conditionKeys = getConditionKeys(flow, initial, [], this.detail, info.volunteerGroupKeyMap); // 参与条件的选项
                        this.flow = this.getConditionFlow(flow);
                    }
                    if (info.ccKeys.length > 0) {
                        if (initial) {
                            info.ccKeys.forEach(key => {
                                this.detail[key] = initial[key];
                            });
                        }
                        getBookCCFlow(this.flow, info.ccKeys, this.detail);
                    }
                    const list = [];
                    form.forEach(item => {
                        if (item.rules) {
                            item.rules = item.rules.map(r => r.rule);
                        }
                        // 接待组件处理子需求应用
                        if (item.component == 'reception') {
                            const extraTemp = info.extraTemp;
                            // 主应用同级审批节点配置
                            const levelNode = info.process.find(f => f.type == 'level');
                            this.dealReceptionComponent(item, extraTemp, extraApply, levelNode);
                        }
                        const res = dealControlItem(item);
                        if(Array.isArray(res)) {
                            res.forEach((c) => {
                                list.push(c);
                            });
                        } else {
                            list.push(res);
                        }
                    });
                    this.list = list;
                } catch (e) {
                    console.error(e);
                }
                info.otherConfig = getTempOtherConfig(info);
                this.info = info;
            },
            getTemp() {
                const id = this.id;
                if(!id) return;
                const url = `/admin/apply-temp/${id}`;
                this.$axios(url, { noTempleFilter: true }).then(res => {
                    this.dealTemp(res);
                });
            },
            getConditionFlow(nodes) {
                const user = getStorageInfo();
                const detail = this.detail;
                return getFlow(nodes, user, detail)
            },
            dealReceptionComponent(item, extraTemp, extraApply, levelNode) {
                if (extraTemp && extraTemp.length > 0) {
                    extraTemp.forEach(child => {
                        child.iconPath = base + child.icon
                        if (child.detail) {
                            const childDetail = JSON.parse(child.detail);
                            // 只显示标志为接待需求的控件
                            child.form = childDetail.filter(c => c.reception);
                            // 完整表单
                            child.fullForm = childDetail;
                            child.form.forEach(c => {
                                c.noGap = true;
                                if (c.rules) {
                                    c.rules = c.rules.map(r => r.rule);
                                }
                            });
                        } else {
                            child.form = [];
                        }
                        child.selected = true; // 表单默认选中
                        child.open = true; // 审批流默认显示
                        if (extraApply) {
                            let te = extraApply.find(a => a.temp_id == child.id);
                            if (te) {
                                child.data == te;
                                let detailForm = [];
                                let subedData = JSON.parse(te.detail);
                                let subedForm = JSON.parse(te.temp_snap)
                                constructForm(detailForm, subedData, subedForm);
                                child.subedData = subedData;
                                child.detailForm = detailForm;
                                child.initial = subedData;
                                // 会议室、殿堂需要额外设置选择的会议室和殿堂对象
                                if (child.type == 4 || child.type == 5) {
                                    const start = getDate(subedData.begin_time);
                                    const end = getDate(subedData.end_time);
                                    const meetingRoom = {
                                        date: start.pattern("yyyy-MM-dd"),
                                        time: {
                                            start: start.pattern("HH:mm"),
                                            end: end.pattern("HH:mm"),
                                        },
                                        room: {
                                            id: subedData.room_id,
                                            name: subedData.room_name,
                                        },
                                        user: { ...this.data.user }
                                    }
                                    child.meetingRoom = meetingRoom;
                                    child.initial = {
                                        date: meetingRoom.date,
                                        begin_time: meetingRoom.time.start,
                                        end_time: meetingRoom.time.end,
                                        room_name: meetingRoom.room.name,
                                        room_id: meetingRoom.room.id,
                                    }
                                }
                            } else {
                                child.selected = false;
                                child.open = false;
                            }
                        }
                        child.otherConfig = getTempOtherConfig(child);
                        // 同级审批节点对应子应用的审批流配置
                        const levelTemp = levelNode ? levelNode.children.find(c => c.id == child.id) : null;
                        let flow = levelTemp ? levelTemp.nodes : [];
                        child._detail = {}; // 接待子初始化表单
                        child.process = flow;
                        child.volunteerGroupKeyMap = {};
                        child.conditionKeys = getConditionKeys(flow, child.initial, [], child._detail, child.volunteerGroupKeyMap); // 参与条件的选项
                        child.flowList = getFlow(flow, this.data.user, child._detail);
                    });
                    item.extraTemp = extraTemp;
                } else {
                    item.extraTemp = [];
                }
            },
        }
    }
</script>

<style lang="less">
.app-title {
  padding: 16px 0;
  border-bottom: var(--border);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
.app-form {
  flex: 1;
}
.app-progress {
  padding: 24px;
  width: 400px;
  border-left: var(--border);
}
.app-progress-title {
  font-size: 16px;
  font-weight: 600;
}
.app-progress-box {
  margin-top: 8px;
}
</style>
